#wrap {
  width: 600px;
  height: 820px;
  padding: 0;
  overflow: hidden;
}
#frame {
  width: 1480px;
  height: 1920px;
}
#frame {
  -ms-zoom: 0.4;
  -moz-transform: scale(0.4);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.4);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.4);
  -webkit-transform-origin: 0 0;
}
